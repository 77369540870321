/* body{ 
    border: 2px solid #fff7f8;
   padding: 100px; 
 } */

.shine {
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;

  animation-duration: .75s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
}

box {
  height: 104px;
  width: 100px;
}

/* .shimmerContainer {
   display: inline-flex;
   flex-direction: column; 
   margin-left: 25px;
   margin-top: 15px;
   vertical-align: top; 
 } */

lines {
  height: 10px;
  margin-top: 10px;
  width: 100%;
}

photo {
  display: block !important;
  width: 325px;
  height: 100px;
  margin-top: 15px;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}