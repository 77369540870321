/* .rw-combobox-input {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFF;
    border-radius: 6px;
    border: 1px solid #b1b1b1;
    height: 42px;
    margin-bottom: 8px;
    width: 100%;
  }
  
  .rw-combobox-input:focus-within label {
    transform: translate(0, 9px) scale(0.8);
    color: #0a53e4;
  }
  
  .rw-combobox-input .filled {
    transform: translate(0, 9px) scale(0.8);
  }
  
  .rw-combobox-input label {
    position: absolute;
    pointer-events: none;
    transform: translate(0, 19px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    color: #b1b1b1;
    font-size: 13px;
    line-height: 0;
    left: 10px;
  }
  
  .rw-combobox-input input {
    height: 105px;
    border-radius: 6px;
    left: 10px;
    font-size: 13px;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    padding-top: 4%;
  }
  
  .rw-combobox-input input:focus {
    box-shadow: 0 0 0 2px #79b1ff;
    outline: none;
  } */

.rw-widget-input {
  border: none !important;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto");

.selectedCompany {
  margin-top: 12px;
  padding-left: 4px;
  color: black;
  font-size: 13px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

.closeButton {
  margin-top: 10px;
  color: #bfbfbf;
  font-size: 20px;
  padding-right: 11px;
  transition: color 0.25s;
}

.closeButton:hover {
  color: #8c8c8c;
}

/* Confirmation Modal CSS Override */
@media (min-width: 640px) {
  [aria-label="Go to matching gift form in a company portal that lets you begin the matching gift process (opens in a new window)"] {
    height: 100%;
  }

  [aria-label="See guidelines and additional information about the matching gift program (opens in a new window)"] {
    height: 100%;
  }
}

/* .mg-guidelines-button {
  height: 100% !important;
  margin: 0 !important;
} */

/* .selectedCompany::-webkit-input-placeholder {
  font-weight: semibold;
}

.selectedCompany::-moz-placeholder {
  font-weight: semibold;
}

.selectedCompany::-ms-input-placeholder {
  font-weight: semibold;
} */

/* Mubarrat's styling below */
#dd-container .text-center.search-title {
  font-size: 0; /* This will hide the original text */
  line-height: 0; /* Ensures no extra space */
}

#dd-container .text-center.search-title::before {
  content: "Match your donation";
  display: block;
  font-size: 18px; /* Adjust as needed */
  line-height: 1.2; /* Adjust as needed */
  font-family: "Inter", sans-serif;
  font-weight: bold;
  color: black;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: block;
}
#dd-container .dtd-search-box .dtd-search-input {
  width: 100%;
  padding: 12px 15px;
  font-size: 16px;
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

#dd-container .dtd-search-box .dtd-search-input:focus {
  outline: none;
  border-color: #4caf50;
  box-shadow: 0 2px 8px rgba(76, 175, 80, 0.3);
}

#dd-container .dtd-search-box .dtd-search-input::placeholder {
  color: #999;
  font-style: italic;
}

#dd-container .dtd-lets-check.grid-x .cell.medium-4:first-child {
  display: none !important;
}
.lets-check {
  background-color: black !important;
  border-radius: 5px !important;
  width: 320px !important;
  height: 45px !important;
}

.dtd-footer {
  display: none !important;
}

.mg-forms-button {
  background-color: black !important;
  border: 2px solid black !important;
  border-radius: 5px !important;
  width: 150px !important;
  height: 50px !important;
  font-size: 12px !important;
  display: flex !important;
  text-align: center !important;
  justify-content: center;
  align-items: center;
}

.mg-guidelines-button {
  background-color: black !important;
  border: 2px solid black !important;
  border-radius: 5px !important;
  width: 150px !important;
  height: 50px !important;
  font-size: 12px !important;
  display: flex !important;
  text-align: center !important;
  justify-content: center;
  align-items: center;
}

.mg-yes {
  display: none !important;
}

.dtd-section-image-first {
  display: none !important;
}

.dtd-section-header {
  display: none !important;
}

.grid-x.text-center.grid-margin-x {
  display: none !important;
}
.dtd-section-image {
  display: none !important;
}
.grid-x.data-cell {
  display: none !important;
}
.data-cell.text-center.corporate-information {
  display: none !important;
}
.text-center.email-dtd {
  display: none !important;
}
