input#cs-zip::placeholder {
  color: #b1b1b1; /* Change this to your desired color */
  opacity: 1; /* For full color opacity */
}

/* For browsers that do not support the ::placeholder pseudo-element */
input#cs-zip::-webkit-input-placeholder {
  color: #b1b1b1; /* Change this to your desired color */
}
input#cs-zip:-ms-input-placeholder {
  color: #b1b1b1; /* Change this to your desired color */
}
input#cs-zip::-ms-input-placeholder {
  color: #b1b1b1; /* Change this to your desired color */
}
