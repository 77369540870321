@media (min-width: 0px) {
  /* .ant-picker-panel-has-range-hover {
    flex-direction: column;
    left: 0;
    justify-content: center;
    align-items: center;
    text-align: center;
  } */
  /* always show ant-picker-clear */
  [class*="antd"] {
    flex-direction: column;
  }

  .ant-picker-suffix {
    display: none;
  }
  @media (max-width: 639px) {
    .ant-picker-panels {
      position: fixed !important; /* Change to fixed positioning */
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: white; /* Add a background color */
      z-index: 1000; /* Ensure it's above other content */
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px; /* Add padding for content spacing */
    }

    .ant-picker {
      width: 100%; /* Make the picker span the full width */
    }
  }

  /* .ant-picker-panels::hover {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  } */
  .ant-picker-range-arrow {
    display: none !important;
  }

  .ant-picker-focused {
    box-shadow: none;
  }

  /* .ant-picker-range-arrow::before { 
    justify-content: center;
    align-items: center;
    text-align: center;
  } 
  .ant-picker-range-arrow::after { 
    justify-content: center;
    align-items: center;
    text-align: center;
  }  */
}

:root {
  --light: #20bf55;
  --dark: #244f26;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}

.path.circle {
  -webkit-animation: dash 1.15s ease-in-out;
  animation: dash 1.15s ease-in-out;
}

.path.line {
  stroke-dashoffset: 1000;
  -webkit-animation: dash 1.15s 0.41s ease-in-out forwards;
  animation: dash 1.15s 0.41s ease-in-out forwards;
}

.path.check {
  stroke-dashoffset: -100;
  -webkit-animation: dash-check 1.15s 0.41s ease-in-out forwards;
  animation: dash-check 1.15s 0.41s ease-in-out forwards;
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

* {
  box-sizing: border-box;
}

input[type="button"],
input[type="text"],
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border-style: none;
}

html {
  font-size: 16px;
  font-family: Roboto, Open Sans, Segoe UI, sans-serif;
  font-weight: 500;
  font-style: normal;
  text-rendering: optimizeLegibility;
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  background-color: transparent !important;
}

#root {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AppWrapper {
  width: 100%;
  position: relative;
}

@keyframes fade {
  from {
    opacity: 0;
    transform: scale3D(0.95, 0.95, 0.95);
  }

  to {
    opacity: 1;
    transform: scale3D(1, 1, 1);
  }
}

.ApplePayButton:hover {
  opacity: 0.75;
}

.PayByBankButton:hover {
  opacity: 0.75;
}

.FeeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.CheckBox {
  width: 10%;
}

.TransactionFee {
  font-size: 13px;
  width: 90%;
  padding-left: 5%;
  line-height: 1.6;
}

.Form {
  animation: fade 200ms ease-out;
}

.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  will-change: opacity, transform;
  border-radius: 7px;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #819efc;
}

.FormRow:first-child {
  border-top: none;
}

.FormRowLabel {
  width: 15%;
  min-width: 70px;
  padding: 11px 0;
  color: #c4f0ff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes void-animation-out {
  0%,
  to {
    opacity: 1;
  }
}

.FormRowInput:-webkit-autofill {
  -webkit-text-fill-color: #fce883;
  /* Hack to hide the default webkit autofill */
  transition: background-color 100000000s;
  animation: 1ms void-animation-out;
}

.FormRowInput {
  font-size: 16px;
  width: 100%;
  padding: 11px 15px 11px 0;
  color: #fff;
  background-color: transparent;
  animation: 1ms void-animation-out;
}

.FormRowInput::placeholder {
  color: #87bbfd;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.PreInput,
.PostInput {
  margin: 20px 0 0;
}

.SuccessLabel {
  width: 15%;
  min-width: 70px;
  padding: 11px 0;
  color: #023f52;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ErrorLabel {
  font-variant: small-caps;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 11px 0;
  color: #e02731;
}

.FrequencyContainer {
  align-items: center;
  background-color: #fff;
  justify-content: space-between;
  position: relative;
  display: flex;
  height: 42px;
  margin-bottom: 3%;
}

.FrequencyContainerRepeat {
  align-items: center;
  background-color: #fff;
  justify-content: space-evenly;
  position: relative;
  display: flex;
  height: 42px;
  margin-bottom: 3%;
}

.Amounts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 5px;
  border: 1px transparent #b1b1b1;
}

.TipList {
  padding-bottom: 5px;
  border: 1px transparent #b1b1b1;
  justify-content: center;
}

.AmountButton {
  display: inline-block;
  font-size: 18px;
  height: 40px;
  border: 1px solid #b1b1b1 !important;
  border-radius: 6px;
  /* font-weight: 600; */
  cursor: pointer;
  transition: all 100ms ease-in-out;
  will-change: transform, background-color, box-shadow, border;
}

.Amount {
  display: inline-block;
  font-size: 50px;
  font-weight: 395;
  /* Adjust the font weight to make it thinner */
}
.custom-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 14px;
  height: 14px;
  border: 2px solid lightgray;
  border-radius: 3px;
  background-color: lightgray;
  outline: none;
  cursor: pointer;
  position: relative;
}

.custom-checkbox::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 3px;
  width: 3px;
  height: 7px;
  border-style: solid;
  border-color: transparent;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}

.custom-checkbox:checked::before {
  opacity: 1;
  border-color: rgb(91, 90, 90);
}

.custom-checkbox:focus {
  border-color: dodgerblue;
}

.LeftPanelDetails {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 300;
  padding-top: 15px;
  /* Add spacing to the top */
  padding-bottom: 15px;
  /* Add spacing to the bottom */
}

.LeftPanelDetailsEvent {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 300;
  padding-top: 7.5px;
  /* Add spacing to the top */
  padding-bottom: 7.5px;
  /* Add spacing to the bottom */
}

.LeftPanelDetailsUnderline {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 300;
  border-bottom: 1px solid rgb(203, 199, 199);
  /* Add underline */
  padding-bottom: 8px;
  /* Add spacing to the bottom */
}

.LeftPanelDetailsUnderlineNoUnderline {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 300;
  /* Add underline */
  padding-bottom: 2px;
  /* Add spacing to the bottom */
}

.EditButtonOnFee {
  border-radius: 15px;
  background-color: #cbcdcd;
  /* Use a darker gray color */
  margin-right: 7px;
}

.AdditionalDetails {
  font-weight: 550 !important;
  /* font-size: 13px; */
  padding-bottom: 8px;
  /* Add spacing under the text */
  color: #929090;
  /* Create underline with span */
  display: inline;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  position: relative;
  text-underline-offset: 8px;
}

.AdditionalDetailsFields {
  display: flex;
  color: #929090;
  font-weight: 550;

  justify-content: space-between;
  font-size: 13px;
  padding-top: 10px;
  /* Add spacing to the top */
  padding-bottom: 5px;
  /* Add spacing to the bottom */

  /* border-bottom: 1px solid rgb(203, 199, 199); */
  /* Add underline */
}

.LogoForOrg {
  max-width: 100px;
  max-height: 100px;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  /* margin: 0 auto;  */
  /* text-align: center;  */
  padding-bottom: 10px;
}

.TipButton {
  white-space: nowrap;
  border: 1px solid #b1b1b1 !important;
  font-size: 0.89rem;
  height: 30px;
  text-align: center !important;
  align-content: center !important;
  /* font-weight: 600; */
  cursor: pointer;
  transition: all 100ms ease-in-out;
  will-change: transform, background-color, box-shadow, border;
}

.TipButton:first-of-type {
  font-size: 0.8rem;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  margin-right: -1px;
  padding-top: 0.15rem;
  border-right: none !important;
}

.TipButton:nth-last-of-type(3n) {
  border-right: none !important;
}

.TipButton:last-of-type {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-left: none !important;
}

.FrequencyButton {
  display: inline-block;
  font-size: 16px;
  width: 45%;
  height: 42px;
  border-radius: 6px;
  background-color: #000;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  border: 1px solid #b1b1b1 !important;
  will-change: transform, background-color, box-shadow;
}

.AppContainer {
  width: 100%;
  max-width: 840px;
  min-width: 300px;
}

.HostedFormPopupContainer {
  width: 100%;
  max-width: 1000px;
  min-width: 300px;
}

.HostedFormContainer {
  width: 100%;
  height: 100vh;
}

.FormBoxShimmer {
  width: 100%;
  overflow: auto;
  height: auto;
  -webkit-appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding-top: 4%;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 4%;
  border-radius: 8px;
  border: 1px solid #b1b1b1;
  /* margin: 1%; */
}

.FormBoxConfirm {
  width: 100%;
  overflow: auto;
  height: auto;
  -webkit-appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding-top: 4%;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 4%;
  border-radius: 8px;
  border: 1px solid #b1b1b1;
  /* margin: 1%; */
}

.FormBox {
  width: 100%;
  /* overflow: auto; */
  height: auto;
  -webkit-appearance: none;
  display: flex-grow;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding-top: 4%;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 4%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-width: 1px 1px 1px 0;
  /* top, right, bottom, left */
  border-style: solid;
  border-color: #b1b1b1;
  /* z-index: 1;
    position: relative;
    margin: 1%; */
}

.FormBoxNew {
  width: 100%;
  /* overflow: auto; */
  height: auto;
  -webkit-appearance: none;
  display: flex-grow;
  align-items: center;
  justify-content: center;
  background-color: #f7f9fb;
  padding-top: 4%;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 4%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-width: 1px 0 1px 1px;
  /* top, right, bottom, left */
  border-style: solid;
  border-color: #b1b1b1;
  /* z-index: 1;
    position: relative;
    margin: 1%; */
}

.HeaderBox {
  width: 100%;
  height: 30px;
  -webkit-appearance: none;
  align-items: center;
  display: inline-block;
  justify-content: center;
  text-align: center;
  background-color: #f7f9fa;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-top: 1px solid #b1b1b1;
  border-left: 1px solid #b1b1b1;
  border-right: 1px solid #b1b1b1;
  padding-top: 2px;
  font-size: 16px;
  font-family: Roboto, Open Sans, Segoe UI, sans-serif;
  font-weight: 500;
  font-style: normal;
  text-rendering: optimizeLegibility;
  /* margin: 1%; */
}

.helper {
  display: grid;
  padding-top: 5px;
}

.DonationInformationHeader {
  display: inline-block;
  justify-content: center;
  height: 1em;
  padding-top: 1%;
  padding-bottom: 1%;
}

.CustomContainer {
  /* outline: 1px solid #d1d3d6; */
  margin-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid transparent;
  height: 42px;
  margin-bottom: 10px;
  width: 100%;
}

.faLockIcon {
  margin-right: 5px;
  color: #9b9b94;
}

.Disclaimer {
  align-items: center;
  text-align: center;
  margin-top: 1.5%;
  font-size: 7px;
  color: #9b9b94;
}

.Version {
  justify-content: center;
  align-items: center;
  display: flex;
  color: #334234;
}

.CustomInput {
  /* outline: 1px solid #f7f7f7e8; */
  width: 100%;
  height: 40px;
  /* font-family: 'proxima-nova', sans-serif; */
  /* font-size: 16px; */
  border-style: none;
  /* font-weight: 200; */
}

.Currency {
  padding-left: 7.5%;
  padding-right: 2.5%;
  font-size: 16px;
  padding-top: 2%;
  padding-bottom: 2%;
  font-family: Roboto, Open Sans, Segoe UI, sans-serif;
  font-weight: 500;
  border-right: 1px solid #b1b1b1;
  width: 30%;
}

.MonthlyButton {
  width: 50%;
  height: 40px;
}

.OneTimeButton {
  width: 50%;
  height: 40px;
}

.FrequencyButton:active {
  transform: scale(0.99);
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
}

.FrequencyButton.SubmitButton--error {
  transform: translateY(15px);
}

.FrequencyButton.SubmitButton--error:active {
  transform: scale(0.99) translateY(15px);
}

.FrequencyButton:disabled {
  opacity: 2;
  cursor: default;
  box-shadow: none;
}

.AmountButton:active {
  transform: scale(0.99);
}

.AmountButton.SubmitButton--error {
  transform: translateY(15px);
}

.AmountButton.SubmitButton--error:active {
  transform: scale(0.99) translateY(15px);
}

.AmountButton:disabled {
  opacity: 2;
  cursor: default;
  box-shadow: none;
}

.TipButton:active {
  transform: scale(0.99);
}

.TipButton.SubmitButton--error {
  transform: translateY(15px);
}

.TipButton.SubmitButton--error:active {
  transform: scale(0.99) translateY(15px);
}

.TipButton:disabled {
  opacity: 2;
  cursor: default;
  box-shadow: none;
}

.SubmitButton {
  margin-top: 9px;
  display: block;
  position: static;
  font-size: 18px;
  width: 100%;
  height: 40px;
  /* margin: 40px 15px 0; */
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  color: #fff;
  font-weight: 400;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  will-change: transform, background-color, box-shadow;
  /* font-variant: small-caps; */
}

.SubmitButton:active {
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
  transform: scale(0.99);
}

.SubmitButton.SubmitButton--error {
  transform: translateY(15px);
}

.SubmitButton.SubmitButton--error:active {
  transform: scale(0.99) translateY(15px);
}

.SubmitButton:disabled {
  opacity: 0.5;
  cursor: default;
  box-shadow: none;
}

.SubmitButtonPromo {
  display: block;
  position: static;
  font-size: 18px;
  width: 100%;
  height: 40px;
  /* margin: 40px 15px 0; */
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  color: #fff;
  font-weight: 400;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  will-change: transform, background-color, box-shadow;
  /* font-variant: small-caps; */
}

.SubmitButtonPromo:active {
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
  transform: scale(0.99);
}

.SubmitButtonPromo.SubmitButtonPromo--error {
  transform: translateY(15px);
}

.SubmitButtonPromo.SubmitButtonPromo--error:active {
  transform: scale(0.99) translateY(15px);
}

.SubmitButtonPromo:disabled {
  opacity: 0.5;
  cursor: default;
  box-shadow: none;
}

.ErrorMessage {
  color: #fff;
  position: absolute;
  display: flex;
  justify-content: center;
  padding: 0 15px;
  font-size: 13px;
  margin-top: 0px;
  width: 100%;
  transform: translateY(-15px);
  opacity: 0;
  animation: fade 150ms ease-out;
  animation-delay: 50ms;
  animation-fill-mode: forwards;
  will-change: opacity, transform;
}

.ErrorMessage svg {
  margin-right: 10px;
}

.Result {
  margin-top: 50px;
  text-align: center;
  animation: fade 200ms ease-out;
}

.ResultTitle {
  color: #fff;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 17px;
  text-align: center;
}

.ResultMessage {
  color: #9cdbff;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 25px;
  line-height: 1.6em;
  text-align: center;
}

.ResetButton {
  border: 0;
  cursor: pointer;
  background: transparent;
}

.svg-inline {
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
  justify-self: left;
  margin-left: 2%;
  border-radius: 3px;
}

.svg-inline:hover {
  background-color: lightgray;
  transform: scale(1.15);
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15s);
  color: #000;
}

.text-inline {
  height: 1em;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
  justify-self: center;
}

.svg-inline svg {
  display: inline-block;
  height: 1em;
  line-height: 1em;
  /* just for vertical alignment */
}

.custom-label {
  display: block;
  margin-bottom: 10px;
  /* Add some space below the label */
  font-weight: bold;
}

.custom-input {
  display: block;
  width: 100%;
  padding: 10px;
  /* Add some padding to the input */
  box-sizing: border-box;
  /* Make sure padding is included in the width */
}

.Info {
  width: 100%;
  height: 30px;
  padding-left: 10px;
  border-style: none;
}

.Info::-webkit-input-placeholder {
  font-weight: semibold;
}

.Info::-moz-placeholder {
  font-weight: semibold;
}

.Info::-ms-input-placeholder {
  font-weight: semibold;
}

.payWCard {
  width: 100%;
  text-align: center;
  border-bottom: 1.75px solid rgb(209 213 219);
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.payWCard span {
  background: #fff;
  margin-top: 10px;
  /* padding:0 10px;  */
}

#arrow_box1 {
  padding-top: 20px;
  position: relative;
  border-bottom: 1px solid rgb(203, 199, 199);
  padding-bottom: 10px;
}

#arrow_box2 {
  padding-top: 3px;
  position: relative;
  width: 100%;
}

/* #arrow_boxtip {
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-radius: 6px;
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 1.5%;
  background: whitesmoke;
  width: 100%;
  border: 1px solid #b1b1b1b1;
} */

#arrow_box {
  position: relative;
  border-radius: 6px;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 1%;
  background: whitesmoke;
  width: 100%;
  border: 1px solid #b1b1b1b1;
  margin-top: -2%;
  font-size: 75%;
  color: #868686;
  display: inline-block;
}

/* #arrow_box:after,
#arrow_box:before {
  bottom: 100%;
  left: 17.9vw;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

#arrow_box:after {
  border-color: rgba(90, 31, 255, 0);
  border-bottom-color: whitesmoke;
  border-width: 10px;
  margin-left: -10px;
}

#arrow_box:before {
  border-color: rgba(177, 177, 177, 0);
  border-bottom-color: #b1b1b1b1;
  border-width: 11px;
  margin-left: -11px;
} */

#bigTipText {
  font-size: 15px;
  white-space: normal;
}

#smallTipText {
  margin-top: 0%;
  font-size: 75%;
  padding-bottom: 7px;
  color: #868686;
  white-space: normal;
  display: inline-block;
}

#donationAmountSecondPageHolder {
  width: 100%;
  font-size: 75%;
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
  margin-top: -7.5%;
  margin-bottom: -3.25%;
}


.clear-button {
  background: none;
  border: none;
  color: #b1b1b1;
  font-size: 25px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 45%;
  transform: translateY(-50%);
}

.clear-button:hover {
  color: #000;
}

#donationAmountSecondPage {
  margin-bottom: 4px;
  border-radius: 10px;
  width: 100%;
  font-size: 750%;
  text-align: center;
  display: flex;
  justify-content: center;
  letter-spacing: -0.5rem;
  position: relative;
  right: 4%;
}

#donationAmountSecondPageFrequency {
  font-size: 20%;
  display: inline-block;
  margin-top: 18.5%;
  letter-spacing: normal;
  font-weight: 400;
  padding-left: 2%;
}

#donationAmountSecondPageCoverFees {
  font-size: 90%;
  display: block;
  letter-spacing: normal;
}

#roundedBoxForDisplayAmountElements {
  margin-bottom: 10px;
  border-radius: 7px;
  border: 1px solid #d6d6d6;

  background: whitesmoke;
  width: 100%;
  color: white;
}

.Toggle:checked {
  background-color: #000;
}
