@font-face {
  font-family: "Baloo Bhai 2";
  src: url(https://fonts.googleapis.com/css?family=Baloo+Bhai+2);
}

body {
  background-color: white;
}

iframe {
  width: 100%;
  height: 100%;
  border: 0 solid black;
}

.field-wrapper {
  width: 200px;
  height: 30px;
}
.scheduledEndDate {
  font-size: 13px;
  font-weight: 500;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  margin: 0px;
}
.scheduledEndDateContainer {
  position: relative;
}
.separator {
  border-bottom: 1px solid #ccc; /* light grey color */
  margin: 0 auto; /* center the element horizontally */
  padding-top: 20px;
  padding-bottom: 5px;
}
.separator1 {
  border-bottom: 1px solid #ccc; /* light grey color */
  margin: 0 auto; /* center the element horizontally */
  padding-bottom: 5px;
}
.fake-field {
  align-items: center;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #b1b1b1;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  display: flex;
  height: 42px;
  margin-bottom: 2px;
  width: 100%;
  color: #b1b1b1;
  font-size: 13px;
  font-weight: 500;
  padding-right: 5%;
  padding-left: 10px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

.fake-field-paypal {
  width: 100%; /* or any other value */
  max-width: 100%;
}
.paypal-image-style {
  display: block; /* Centers the image in its container */
  margin: 0 auto; /* Auto margins on the sides to center */
  width: 85%; /* Adjust this value to shrink the image */
  max-width: 100px; /* Or use max-width to control the size */
  filter: invert(100%);
}
@keyframes smoothFloatAndRotate {
  0% {
    opacity: 1;
    transform: translate(0, 0) rotate(0deg);
  }

  100% {
    opacity: 0;
    transform: translate(2px, -300px) rotate(-20deg);
  }
}

.heartIcon {
  animation: smoothFloatAndRotate 3s cubic-bezier(0.32, 0.04, 0.85, 0.97)
    forwards;
  position: absolute;
  left: 0; /* Adjust starting position as needed */
  color: red; /* Or any color you prefer */
  font-size: 10px; /* Adjust size as needed */
}

.donor-field-select {
  align-items: center;
  background-color: #fff;
  border-radius: 6px;
  height: 42px;
  margin-bottom: 2px;
  width: 100%;
  border: 1px solid #b1b1b1;
  color: #b1b1b1;
  font-size: 13px;
  font-weight: 500;
  padding-right: 5%;
  padding-left: 10px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.donor-field-selected {
  align-items: center;
  background-color: #fff;
  border-radius: 6px;
  height: 42px;
  margin-bottom: 2px;
  width: 100%;
  border: 1px solid #b1b1b1;
  font-size: 13px;
  font-weight: 500;
  padding-right: 5%;
  padding-left: 10px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.donor-field-country-selected {
  align-items: center;
  background-color: #fff;
  border-radius: 6px;
  height: 42px;
  margin-bottom: 2px;
  width: 100%;
  border: 1px solid #b1b1b1;
  font-size: 13px;
  font-weight: 500;
  padding-right: 5%;
  padding-left: 10px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

/* For IE (thanks to @SaiManoj) */
donor-field-select::-ms-expand {
  display: none;
}

.donor-field-select-tip {
  align-items: center;
  border-radius: 6px;
  height: 20%;
  margin-bottom: 2px;
  border: 1px solid #b1b1b1;
  color: #b1b1b1;
  font-size: 17px;
  font-weight: 500;
  float: right;
  padding-right: 10%;
  padding-left: 10%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

.charitystack {
  justify-content: center;
  cursor: pointer;
  color: #9a9a9a;
  align-items: center;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  /* margin-top: auto; */
  font-size: 15px;
  padding-right: 4px;
  font-family: "PlantagenetCherokee";
}

.charity {
  justify-content: center;
  cursor: pointer;
  color: #4fb286;
  align-items: center;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  /* margin-top: auto; */
  font-size: 15px;
  /* padding-right: 1px; */
  font-family: "PlantagenetCherokee";
}

.stack {
  justify-content: center;
  cursor: pointer;
  color: #323232;
  align-items: center;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  /* margin-top: auto; */
  font-size: 15px;
  font-family: "PlantagenetCherokee";
}

/* For IE (thanks to @SaiManoj) */
donor-field-select-tip::-ms-expand {
  display: none;
}

.donor-field-amount {
  align-items: center;
  background-color: #fff;
  position: relative;
  border-radius: 6px;
  border: 1px solid #b1b1b1;
  display: flex;
  height: 42px;
  margin-bottom: 10px;
  width: 100%;
}
.donor-field-amount:focus-within label {
  transform: translate(0, 4px) scale(0.7);
  color: #0a53e4;
}

.donor-field-amount .filled {
  transform: translate(0, 4px) scale(0.7);
}

.donor-field-amount label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 10px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #b1b1b1;
  font-size: 14px;
  top: 0;
  line-height: normal;
  left: 28%;
}

.donor-field-amount input {
  height: 35px;
  border-radius: 6px;
  padding: 2% 0px 0px 10px;
  font-size: 14px;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.donor-field-amount input:focus {
  box-shadow: 0 0 0 2px #79b1ff;
}
.donor-field-custom {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #b1b1b1;
  margin-bottom: 8px;
  width: 100%;
}

.donor-field-custom:focus-within label {
  transform: translate(0, 9px) scale(0.8);
  color: #0a53e4;
}

.donor-field-custom .filled {
  transform: translate(0, 9px) scale(0.8);
}

.donor-field-custom label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 19px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #b1b1b1;
  font-size: 13px;
  line-height: 0;
  left: 10px;
}

.donor-field-custom textarea {
  height: 100%;
  border-radius: 6px;
  left: 10px;
  font-size: 13px;
  height: 100px;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  padding-top: 4%;
  resize: none; /* prevents the user from resizing the textarea */
}

.donor-field-custom textarea:focus {
  box-shadow: 0 0 0 2px #79b1ff;
}

.donor-field {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #b1b1b1;
  height: 42px;
  margin-bottom: 2px;
  width: 100%;
}

.donor-field:focus-within label {
  transform: translate(0, 9px) scale(0.8);
  color: #0a53e4;
}

.donor-field .filled {
  transform: translate(0, 9px) scale(0.8);
}

.donor-field label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 19px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #b1b1b1;
  font-size: 13px;
  line-height: 0;
  left: 10px;
}

.donor-field input {
  height: 105px;
  border-radius: 6px;
  left: 10px;
  font-size: 13px;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  padding-top: 16px;
}

.donor-field input:focus {
  box-shadow: 0 0 0 2px #79b1ff;
}

#field-wrapper-name,
#field-wrapper-tags {
  width: 100%;
  height: 100%;
  padding-left: 10px;
}

#field-wrapper-address_line1 {
  width: 115px;
  height: 100%;
  padding-left: 10px;
}

#field-wrapper-address_line2 {
  width: 13%;
  height: 100%;
}

#field-wrapper-address_city {
  padding-left: 6px;
  width: 100px;
  height: 100%;
}

#field-wrapper-address_region {
  width: 25px;
  height: 100%;
}

#brand {
  background-color: #333;
  border-radius: 6px;
  color: #fff;
  display: block;
  font-family: arial;
  font-size: 12px;
  margin: 3px;
  overflow: hidden;
  padding: 1px 3px;
  text-align: center;
  width: 16%;
}

#field-wrapper-number {
  width: 42%;
  height: 100%;
}

#field-wrapper-expiration_date {
  width: 20%;
}

#field-wrapper-security_code {
  width: 20%;
}

#field-wrapper-address_postal_code {
  width: 60%;
}

/* Double the donation confirmation modal styling */
/* Make it have no border and no box shadow
make the background clear */
.dtd-callout {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}
